import { Box, Flex, Image } from "@chakra-ui/react";
import Aos from "aos";
import { COLORS } from "../../../styles/theme";
import LandLordLoanImage from "../../../assets/landlord-loan.png";
import CustomButton from "../../custom-button/custom-button.component";
import React from "react";

function LoanBanner() {
  React.useEffect(() => {
    Aos.init({ duration: 2000, once: true });
  }, []);
  return (
      <Flex
        flexDir="row"
        bgColor={COLORS.BACKGROUND_COLOR}
        h={{ base: "25rem", md: "32rem", xl: "37rem" }}
        width="100%"
        py={{ base: "12%", sm: "8%", md: "8%", lg: "5%"}}
        px={{ base: "5%", md: "10%" }}
        justify="space-between"
        align="end"
      >
        <Flex
          justify="space-between"
          w={{ base: "100%", md: "40%"}}
          px={{ base: "10%", md: "0" }}
          align={{ base: "center", md: "flex-start"}}
          flexDir="column"
          // mt="5%"
          h="80%"
          textAlign={{ base: "center", md: "start"}}
          // mb="5%"
        >
          <Box
            fontSize={{
              base: "md",
              sm: "xl",
              lg: "2xl",
              // xl: "xl",
            }}
            fontWeight="bold"
          >
            Get a <Box as="span" color={COLORS.PRIMARY_COLOR}>loan</Box> based on your <br />annual rental income <br />in as fast as <Box as="span" color={COLORS.PRIMARY_COLOR}>2 days.</Box>
          </Box>
          <Box fontSize={{ base: "sm", md: "sm", lg: "md" }}>          
          We understand that landlords can need emergency funds. Why wait till your tenants pay their rent when you can access their rent today to sort your emergencies?
          </Box>
          <CustomButton 
            onClick={(e) => {
                e.preventDefault();
                window.location.href =
                "https://docs.google.com/forms/d/11FQ481IlfZLWdFWC0pR7JkLrhSoSj8mthSNJJNw7efc/edit";
            }}  
            w="8rem">Apply now</CustomButton>
        </Flex>
        <Flex display={{ base: "none", md: "flex" }} data-aos="slide-left" w={{md: "55%", lg: "50%"}} align="center" h={{ md: "80%", lg:"90%"}}>
          <Image alt="" src={LandLordLoanImage} w="auto" h="100%" />
        </Flex>
      </Flex>
  );
}

export default LoanBanner;
