import { Box, BoxProps, Flex } from "@chakra-ui/react";
import React from "react";
import { BOXSHADOW } from "../../../styles/theme";

type Props = {
  title: string;
  image: string;
  description: string;
} & BoxProps;

const RentBenefit: React.FC<Props> = ({ title, image, description, ...props }) => {
  return (
    <Flex
      flexDir="column"
      bg={`url(${image})`}
      backgroundSize="cover"
      backgroundPosition="right"
      backgroundRepeat="no-repeat"
      w={{ base: "80%", sm: "44%", xl: "42%" }}
      mx={{ base: "10%", sm: "3%", xl: "4%" }}
      h={{ base: "16rem", lg: "20rem" }}
      mb={{ base: "2rem", lg: "4rem" }}
      borderRadius="5px"
      minH="6rem"
      pt={{ base: "1.1rem", sm: "0.5rem", md: "1.5rem"}}
      px={{ base: "4%", sm: "2%", md: "4%" }}
      // align="center"
      // justify="center"
      // textAlign="center"
      boxShadow={BOXSHADOW}
      _hover={{
        transform: "scale(1.1)"
      }}
      {...props}
    >
      <Box
        fontSize={{ base: "sm", md: "md", lg: "lg" }}
        fontWeight="semibold"
        color="#011627"
        mt={{ base: "0.5rem", lg: "1rem"}}
      >
        {title}
      </Box>
      <Box
        fontSize={{ base: "xs", sm: "xx-small", md: "xs", lg: "sm", xl: "md" }}
        mt={{ base: "0.5rem", lg: "1.5rem"}}
      >
        {description}
      </Box>
    </Flex>
  );
};

export default React.memo(RentBenefit);
