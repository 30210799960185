import React from "react";
import Footer from "../components/footer/footer";
import Header from "../components/header/header.component";
import JoinCommunity from "../components/landlord-property/join-community/join-community";
import PaymyrentRenovation from "../components/landlord-property/paymyrent-renovation/paymyrent-renovation";
import PropertyBanner from "../components/landlord-property/property-banner/property-banner";
import PropertyBenefits from "../components/landlord-property/property-benefits/property-benefits";

function LandLordProperty() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <section>
      <Header />
      <PropertyBanner />
      <PaymyrentRenovation />
      <PropertyBenefits />
      <JoinCommunity />
      <Footer />
    </section>
  );
}

export default React.memo(LandLordProperty);
