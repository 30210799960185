import Content from "./content";

function Contact() {
  return (
    <Content title="HOW CAN I CONTACT PAYMYRENT?">
      If you have any questions, comments, or concerns regarding these Terms or
      the Services, please contact us at support@paymyrent.ng
    </Content>
  );
}

export default Contact;
