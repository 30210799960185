export type LoanBenefitModel = {
    key: number;
    title: string;
    delay: string;
    text: string;
  };
  
  export const benefits: LoanBenefitModel[] = [
    {
      key: 1,
      title: "Guaranteed Loan",
      delay: "200",
      text: "While banks can’t give you loan based on your tenancy income, we do. We understand that your tenant’s income will come in and we are ready to give you a loan based on that.",
    },
    {
      key: 2,
      title: "Low Interest",
      delay: "300",
      text: "Our interest rate is cheaper than your commercial banks. Its simply because we understand your true value to the community."
    },
    {
      key: 3,
      title: "Fast Approval",
      delay: "400",
      text: "Not to worry. There is no plenty paper works. You get approved in less than 48 hours. We are fast like that.",
    },
  ];