import React from "react";
import FAQContent from "../components/faqs/faq-content";
import Footer from "../components/footer/footer";
import Header from "../components/header/header.component";

function FAQs() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <section>
      <Header />
      <FAQContent />
      <Footer />
    </section>
  );
}

export default React.memo(FAQs);
