import { Social } from "../../footer/footer-data";
import Instagram from "../../../assets/colored-instagram.png";
import LinkedIn from "../../../assets/colored-linkedin.png";
import Facebook from "../../../assets/colored-facebook.png";
import Twitter from "../../../assets/colored-twitter.png";

export const coloredSocials: Social[] = [
  {
    key: 1,
    image: Facebook,
  },
  {
    key: 2,
    image: Instagram,
  },
  {
    key: 3,
    image: LinkedIn,
  },
  {
    key: 4,
    image: Twitter,
  },
];
