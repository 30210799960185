import { Box } from "@chakra-ui/react";
import Content from "./content";

function Information() {
  const textStyle: any = {
    mt: "0.5%",
    fontSize: { base: "xx-small", sm: "xs", md: "sm" },
  };

  const titleStyle: any = {
    mt: "1%",
    w: "100%",
    textAlign: "start",
    fontWeight: "semibold",
    fontSize: { base: "xx-small", sm: "xs", md: "sm" },
  };
  const listItemStyle: any = {
    as: "li",
    fontWeight: "semibold",
    fontSize: { base: "xx-small", md: "xs" },
  };
  return (
    <Content title="INFORMATION WE COLLECT">
      <Box {...titleStyle}>Information you provide us</Box>
      <Box {...textStyle}>
        In general, you can visit https://paymyrent.ng/ without telling us who
        you are or revealing any information about yourself. When you submit an
        inquiry via our Services or register for a Paymyrent account, we may
        collect personal information from you, which may include your name,
        email address, mobile phone number, banking information, and other
        information that identifies you (collectively, "Personal Information").
        By providing your Personal Information to us, you expressly agree to our
        collection, use, storage, and disclosure of such information as
        described in this Privacy Policy.
        <br />
        We may also ask you to create login information for your Paymyrent
        account, such as a username and password. When you provide your mobile
        phone number, we may ask for your consent to receive text messages
        relating to our Services at that number.
      </Box>
      <Box {...titleStyle}>Information about your transaction</Box>
      <Box {...textStyle}>
        We collect Personal Information about your transactions with us and
        others.
      </Box>
      <Box {...titleStyle}>Information from Third party</Box>
      <Box {...textStyle}>
        We also collect Personal Information about you from other companies. For
        instance, we may receive Personal Information about you from a consumer
        reporting agency.
      </Box>
      <Box {...titleStyle}>Information automatically collected</Box>
      <Box {...textStyle}>
        We (or our service providers acting on our behalf) may collect
        information about your use of our Services. This information may include
        Personal Information as well as statistical information that does not
        identify you ("Analytics"). Some Analytics may be correlated with
        Personal Information. When Analytics are, directly or indirectly,
        associated or combined with Personal Information, such Analytics will be
        considered Personal Information for purposes of this Privacy Policy.
        Information that we automatically collect in connection with your access
        or use of our Services may include:
        <br />
        <Box as="span" textDecoration="underline">
          Device Information:
        </Box>{" "}
        We may collect Device-specific information (such as hardware model,
        operating system version, unique Device identifiers, and mobile network
        Information, including your mobile phone number). We may associate your
        Device identifiers or mobile phone number with your Paymyrent account.
        <br />
        <Box as="span" textDecoration="underline">
          Log Information:
        </Box>{" "}
        We may record or log information from your Devices, their software, and
        your activity accessing or using our Services. This information may
        include:
      </Box>
      <Box mt="1%" ml="5%" as="ul">
        <Box
          as="li"
          fontSize={{ base: "xx-small", md: "xs" }}
          fontWeight="semibold"
        >
          The Device's Internet Protocol ("IP") address
        </Box>
        <Box {...listItemStyle}>
          Identification numbers associated with your Devices
        </Box>
        <Box {...listItemStyle}>
          Device event information, such as crashes, system activity, and
          hardware settings
        </Box>
        <Box {...listItemStyle}>Location preferences</Box>
        <Box {...listItemStyle}>Date and time stamps of transactions</Box>
        <Box {...listItemStyle}>System configuration information</Box>
        <Box {...listItemStyle}>Other interactions with our Services</Box>
      </Box>
      <Box {...titleStyle}>
        Information Collected through Cookies and similar Technologies
      </Box>
      <Box {...textStyle}>
        We use cookies to personalize our Services for you and to collect
        aggregate information about usage of our Services. A cookie is a text
        file or other local storage identifier provided by your browser or
        associated applications. We use cookies for record-keeping purposes and
        to enhance the quality of your use of our Services. The cookies assign
        random, unique numbers to your Devices to enable our systems to
        recognize your Devices and to allow us to see how you use our Services.
        Additional general information about cookies and how they work is
        available at www.allaboutcookies.org.
        <br />
        The cookies we use in connection with our Services include: Session
        cookies: Session cookies are temporary cookies that expire and are
        automatically erased whenever you close your browser window. We use
        session cookies to grant users access to content and to enable actions
        they must be logged into their Paymyrent account to perform.
        <br />
        Persistent cookies: Persistent cookies usually have an expiration date
        in the distant future and remain in your browser until they expire or
        you manually delete them. We use persistent cookies to better understand
        usage patterns so we can improve our Services. For example, we may use a
        persistent cookie to associate you with your Paymyrent account or to
        remember your choices for our Services.
        <br />
        Third-party cookies: We permit certain third parties to place cookies
        through our Services to provide us with better insights into the use of
        our Services and user demographics and to advertise our Services to you.
        These third parties may collect information about your online activities
        over time and across different websites when you access or use our
        Services. For example, we utilize Google Analytics to analyze usage
        patterns for our Services. Google Analytics generates a cookie to
        capture information about your use of our Services, which Google uses to
        compile reports on website activity for us and to provide other related
        services. Google may use a portion of your IP address to identify its
        cookie, but this will not be associated with any other data held by
        Google. We may also permit third-party service providers to place
        cookies for our Services, as indicated above, to perform analytic or
        marketing functions where you are notified of them and you have
        consented to the usage. We do not control the use of such third-party
        cookies or the resulting information, and we are not responsible for any
        actions or policies of such third parties.
        <br />
        By accessing or using our Services, you consent to the placement of
        cookies on your Devices as described in this Privacy Policy. If you
        prefer not to receive cookies through our Services, you may control how
        your browser responds to cookies by adjusting the privacy and security
        settings of your web browser. Unless you set your browser settings to
        refuse all cookies, our system may issue cookies when you access or use
        our Services. If you set your browser settings to refuse all cookies,
        the performance of certain features of our Services may be limited or
        not work at all.
        <br />
      </Box>
      <Box {...titleStyle}>Do-Not-Track-Signals</Box>
      <Box {...textStyle}>
        Do Not Track ("DNT") is an optional browser setting that allows you to
        express your preferences regarding tracking by advertisers and other
        third parties. We do not use technology that recognizes DNT signals from
        your web browser.
      </Box>
    </Content>
  );
}

export default Information;
