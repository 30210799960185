import React, { Suspense } from "react";
import { SplashComponent } from "../components/splash-screen/splash-screen.component";

const LandingComponent = React.lazy(() => import("../components/landing/landing"));

function Landing() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <section>
      <Suspense fallback={<SplashComponent />}>
        <LandingComponent />
      </Suspense>
    </section>
  );
}

export default React.memo(Landing);
