import { Box, Flex } from "@chakra-ui/react";
import React from "react";

type Props = {
  title: string;
  children: any;
};
const Content: React.FC<Props> = ({ title, children }) => {
  const textStyle: any = {
    mt: "1.5%",
    fontSize: { base: "xx-small", sm: "xs", md: "sm" },
  };

  const titleStyle: any = {
    mt: "3%",
    w: "100%",
    textAlign: "start",
    fontWeight: "semibold",
    fontSize: { base: "xs", md: "sm" },
  };

  return (
    <Flex flexDir="column">
      <Box {...titleStyle}>{title}</Box>
      <Box {...textStyle}>{children}</Box>
    </Flex>
  );
};

export default Content;
