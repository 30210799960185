import Content from "./content";

const UseInformation = () => {
  return (
    <Content title="HOW WE USE INFORMATION">
      We may use Analytics as described elsewhere in this Privacy Policy and for
      research and commercial purposes, such as to improve our Services.
      <br />
      <br />
      We may use Personal Information for the purposes described elsewhere in
      this Privacy Policy and internally for our general commercial purposes,
      including, among other things, to offer our products and services and
      products and services of third parties that we think you might find of
      interest, but only Paymyrent and our third-party service providers
      involved in distributing the offers or providing the products or services
      will have access to your Personal Information. Our third-party service
      providers will only be permitted to use Personal Information for that
      intended purpose.
      <br />
      <br />
      We may use your email address to respond to your inquiries and to provide
      you information about our Services. You may elect not to receive
      promotional emails from us either by "unsubscribing" to an email you
      receive from us or by contacting us as indicated below. If you unsubscribe
      from receiving emails from us, we may still send you non-promotional
      emails, such as emails about your Paymyrent account or our ongoing
      business relations, unless you withdraw your consent to receive electronic
      communications as provided in our Terms of Use.
    </Content>
  );
};

export default UseInformation;
