import { FaHome } from "react-icons/fa";
import { GiGrowth } from "react-icons/gi";
import { MdContactPhone, MdQuestionAnswer } from "react-icons/md";

type LinkProps = {
  key: number;
  title: string;
  url: string;
  as?: any;
};

export const headerLinksData: LinkProps[] = [
  {
    key: 1,
    title: "Landlords",
    url: "/",
    as: FaHome,
  },
  {
    key: 2,
    title: "Investment",
    url: "/investment",
    as: GiGrowth,
  },
  {
    key: 3,
    title: "FAQ",
    url: "/faq",
    as: MdQuestionAnswer,
  },
  {
    key: 4,
    title: "Contact Us",
    url: "/contact-us",
    as: MdContactPhone,
  },
];

export const homeHeaderLinksData: LinkProps[] = [
  // {
  //   key: 1,
  //   title: "Home",
  //   url: "/",
  //   as: FaHome,
  // },
  {
    key: 1,
    title: "LandLord Loan",
    url: "/landlord",
    as: FaHome,
  },
  {
    key: 2,
    title: "Landlord Rent Guaranteed",
    url: "/landlord-rent",
    as: FaHome,
  },
  {
    key: 3,
    title: "Property Management",
    url: "/landlord-property-manage",
    as: FaHome,
  },
  {
    key: 4,
    title: "Renovate Property",
    url: "/landlord-property-renovate",
    as: FaHome,
  },
  // {
  //   key: 6,
  //   title: "Tenant Screening",
  //   url: "/",
  //   as: FaHome,
  // },
]
