import { Box, Flex, Image } from "@chakra-ui/react";
import Aos from "aos";
import { COLORS } from "../../../styles/theme";
import LandLordRent from "../../../assets/landlord-rent-hero.png";
import CustomButton from "../../custom-button/custom-button.component";
import React from "react";

function RentBanner() {
  React.useEffect(() => {
    Aos.init({ duration: 4000, once: true });
  }, []);
  return (
    <Flex
        flexDir="row"
        bgColor={COLORS.BACKGROUND_COLOR}
        h={{ base: "23rem", md: "35rem", xl: "38rem" }}
        width="100%"
        pt={{ base: "5%", lg: "7%"}}
        mt="3rem"
        px={{ base: "5%", sm: "10%" }}
        justify="space-between"
      >
        <Flex
          justify="space-between"
          w={{ base: "100%", md: "45%"}}
          align={{ base: "center", md: "flex-start"}}
          flexDir="column"
          textAlign={{ base: "center", md: "start"}}
          my="5%"
          px={{ base: "5%", md: "0"}}
          h={{ base: "70%", md: "60%"}}
        >
          <Box
            fontSize={{
              base: "xs",
              lg: "md"
            }}
            fontWeight="medium"
            color={COLORS.PRIMARY_COLOR}
          >
            Land Lord Rent Guaranteed
          </Box>
          <Box
            fontSize={{
              base: "2xl",
              lg: "3xl",
              xl: "4xl",
            }}
            fontWeight="bold"
          >
            Join over <Box as="span" color={COLORS.PRIMARY_COLOR}>3,000+ property</Box> <br />owners who use us
          </Box>
          <Box fontSize={{ base: "xs", sm: "sm", lg: "md" }}>          
            Rent guarantee protects landlords against loss of rental income should tenants be unable to pay their rent.
          </Box>
          <CustomButton w="8rem">Get It</CustomButton>
        </Flex>
        <Box display={{ base: "none", md: "block" }} data-aos="slide-left" w="50%">
          <Image alt="" src={LandLordRent} w="auto" h="100%" />
        </Box>
      </Flex>
  );
}

export default RentBanner;
