export type Access = {
    key: number;
    text: string;
};

export const accessList: Access[] = [
    {
        key: 1,
        text: "Rent guarantee is recommended for any landlord with tenants.",
    },
    {
        key: 2,
        text: "Rent guarantee can work for new and existing tenants.",
    },
    {
        key: 3,
        text: "Even the best tenants may find themselves in a difficult situation and unable to pay the rent you rely on.",
    },
    {
        key: 4,
        text: "So rent guarantee is a valuable safety net.",
    },
]