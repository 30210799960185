import { Box, Flex, Image } from "@chakra-ui/react";
import Logo from "../../assets/logo.png";
import { COLORS } from "../../styles/theme";
import FooterColumn from "./footer-column";
import {
  appFeatures,
  company,
  help,
  Social,
  socials,
  transparency,
  TransparencyItem,
} from "./footer-data";
import NavLinkItem from "../link-item/header-links.component";
import { Link } from "react-router-dom";
import React from "react";

function Footer() {
  return (
    <Flex
      flexDir="column"
      bgColor="#ECEBEB"
      minH={{ base: "30rem", md: "35rem" }}
      width="100%"
      py="5%"
      px={{ base: "5%", sm: "10%" }}
      justify="space-between"
    >
      <Flex
        flexDir={{ base: "column", sm: "row" }}
        w="100%"
        justify="space-between"
      >
        <NavLinkItem
          mb={{ base: "3rem", sm: "0" }}
          w={{ base: "30%", sm: "20%" }}
          aria-label="homepage"
          url="/"
        >
          <Image
            alt=""
            w="100%"
            src={Logo}
            h={{ base: "1.5rem", sm: "1.5rem", md: "1.8rem", lg: "2.1rem" }}
          />
        </NavLinkItem>
        <Flex
          w={{ base: "100%", sm: "80%", md: "70%" }}
          justify="space-between"
        >
          <FooterColumn title="Features" list={appFeatures} />
          <FooterColumn title="Company" list={company} />
          <FooterColumn title="Help" list={help} />
          {/* <FooterColumn title="Transparency" list={transparency} /> */}
          <Flex flexDir="column" align="flex-start">
            <Box
              mb="1.5rem"
              fontSize={{ base: "xx-small", sm: "xs" }}
              fontWeight={{ base: "semibold", sm: "medium" }}
            >
              Transparency
            </Box>
            {transparency.map((feature: TransparencyItem) => (
              <Link to={feature.route}>
                <Box
                  mb="1.3rem"
                  textAlign="start"
                  fontSize={{base: "xx-small", md: "xs"}}
                  key={feature.key}
                >
                  {feature.name}
                </Box>
              </Link>
            ))}
          </Flex>
        </Flex>
      </Flex>
      <Flex mt="3rem" w="100%">
        <Flex w="50%" flexDir="column" align="flex-start">
          <Box mb="0.8rem" fontSize="xs" fontWeight="medium">
            Contact
          </Box>
          <Box mb="2rem" fontSize={{base: "xx-small", md: "xs"}}>
            support@paymyrent.ng
          </Box>
          <Flex>
            {socials.map((social: Social) => (
              <Image
                alt=""
                mr="0.5rem"
                key={social.key}
                h={{ base: "1.5rem", md: "2rem" }}
                w={{ base: "1.5rem", md: "2rem" }}
                src={social.image}
              />
            ))}
          </Flex>
        </Flex>
        <Flex flexDir="column" align="flex-start">
          <Box mb="0.3rem" fontSize="xs" fontWeight="medium">
            Address
          </Box>
          <Box lineHeight="1.5rem" fontSize={{base: "xx-small", md: "xs"}} textAlign="start">
            37, Akin- Osiyemi Street,
            <br />
            Allen-Avenue,
            <br />
            Ikeja,
            <br />
            Lagos State
          </Box>
        </Flex>
      </Flex>
      <Flex
        fontSize={{ base: "xx-small", sm: "xs", md: "sm" }}
        align="center"
        textAlign="center"
        mt="3rem"
        px={{ base: "10%", md: "20%" }}
      >
        Paymyrent is a product of Confidence Micro Finance Bank which is
        regulated by the Central Bank of Nigeria. Depositor’s fund is insured by
        NDIC. Our investment professionals invest savers funds in financial
        instruments and manage the investments to ensure optimum return.
      </Flex>
      <Flex mt="3rem" flexDir="column" align="center">
        <Box fontSize={{ base: "xx-small", md: "xs" }} fontWeight="semibold">
          Copyright&copy;2021 Paymyrent
        </Box>
        <Box fontWeight="semibold" fontSize={{ base: "xx-small", md: "xs" }}>
          Powered By:{" "}
          <Box color={COLORS.PRIMARY_COLOR} as="span">
            Confidence Micro Finance Bank.
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default React.memo(Footer);
