import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import Aos from "aos";
import { COLORS } from "../../../styles/theme";
import CustomButton from "../../custom-button/custom-button.component";

function JoinCommunity() {
  React.useEffect(() => {
    Aos.init({ duration: 500, once: true });
  }, []);

  return (
    <Flex
      flexDir="column"
      pos="relative"
      bgColor={COLORS.BACKGROUND_COLOR}
      borderRadius="5px"
      minH={{ base: "18rem", sm: "22rem" }}
      width="100%"
      pt={{ base: "2rem", md: "5rem" }}
      pb={{ base: "2rem", md: "10rem" }}
    >
      <Box pos="absolute" bgColor="rgba(249, 0, 0, 0.11)" h={{ base: "10rem", md: "12rem", lg: "15rem"}} w={{ base: "10rem", md: "12rem", lg: "15rem"}} borderRadius="50%" left="-4rem" />
      <Box pos="absolute" bgColor="rgba(249, 0, 0, 0.11)" h={{ base: "10rem", md: "12rem", lg: "15rem"}} w={{ base: "10rem", md: "12rem", lg: "15rem"}} borderRadius="50%" right="-4rem" />
      <Flex bgColor="rgba(237, 218, 163, 0.15)" mt="7rem" mx={{ base: "5%", sm: "6%" }} px={{ base: "5%", sm: "10%" }} py="5%" flexDir="column" align="center" justify="center">
        <Box 
          fontWeight="semibold"
          fontSize={{
            base: "md",
            sm: "lg",
            md: "xl",
            lg: "2xl",
            xl: "3xl",
          }}
          color={COLORS.PRIMARY_COLOR}
        >
          JOIN OUR LANDLORD COMMUNITY
        </Box>
        <Box 
          fontSize={{
            base: "xs",
            sm: "sm",
            lg: "md",
          }}
          textAlign="center"
          my="1.5rem"
        >
          We love our community with over 1,000 landlords in our community. You don’t have to feel alone as a landlord, come join the community!
        </Box>
        <CustomButton w="8rem">Join now</CustomButton>
      </Flex>
    </Flex>
  );
}

export default React.memo(JoinCommunity);
