import { Box, BoxProps, Flex } from "@chakra-ui/react";
import React from "react";
import { BOXSHADOW } from "../../../styles/theme";

type Props = {
  title: string;
  image: string;
  description: string;
} & BoxProps;

const RentBenefit: React.FC<Props> = ({ title, image, description, ...props }) => {
  return (
    <Flex
      flexDir="column"
      bg={`url(${image})`}
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      w={{ base: "42%", md: "42%" }}
      mx={{ base: "4%", md: "4%" }}
      h={{ base: "13rem", md: "20rem" }}
      mb={{ base: "3rem", md: "5rem" }}
      borderRadius="5px"
      minH="6rem"
      px={{ base: "5%", sm: "5%" }}
      bgColor="#FEE0D7"
      align="center"
      justify="center"
      textAlign="center"
      boxShadow={BOXSHADOW}
      _hover={{
        transform: "scale(1.1)"
      }}
      {...props}
    >
      <Box
        fontSize={{ base: "xs", sm: "sm", md: "md" }}
        fontWeight="semibold"
        mt={{ base: "0.5rem", md: "1rem"}}
      >
        {title}
      </Box>
      <Box
        fontSize={{ base: "xx-small", sm: "xs", md: "sm" }}
        mt={{ base: "1rem", md: "1.5rem"}}
      >
        {description}
      </Box>
    </Flex>
  );
};

export default React.memo(RentBenefit);
