import { Box, Flex, Icon } from "@chakra-ui/react";
import Aos from "aos";
import { BOXSHADOW, COLORS } from "../../../styles/theme";
import { Product, products } from "./product-list-data";
import React from "react";
import {AiOutlineExclamationCircle} from "react-icons/ai";
import {MdOutlineDoubleArrow} from "react-icons/md";
import { Link } from "react-router-dom";

function ProductList() {
  React.useEffect(() => {
    Aos.init({ duration: 2000, once: true });
  }, [])

  return (
      <Flex
        flexDir="column"
        bgColor="#FFFAFA"
        minH={{ base: "20rem", sm: "25rem", md: "30rem", xl: "35rem" }}
        width="100%"
        pt="5%"
        px={{ base: "5%", sm: "10%" }}
      >
        <Box 
            fontSize={{
                base: "sm",
                sm: "md",
                md: "lg",
                lg: "xl",
                xl: "2xl",
            }}
            fontWeight="bold"
            color="black"
        >
                Find the{" "}<Box as="span" color={COLORS.PRIMARY_COLOR}>Best Property to Invest</Box>
        </Box>
        <Box 
            my="2rem"
            fontSize={{
                base: "sm",
                sm: "sm",
                md: "md",
                lg: "lg",
                xl: "xl",
            }}
            fontWeight="bold"
            color="black"
        >
               Properties
        </Box>
        <Flex bgColor="white" borderRadius="20px" flexWrap="wrap" p="6%" mb="10rem">
        {products.map((product: Product) => {
          return (
            <Box
              key={product?.key}
              data-aos="fade"
              bgColor="#E8E8E8"
              borderRadius="5px"
              data-aos-delay={product?.delay}
              w={{ base: "90%", sm: "42%", md: "30%", lg: "29.3%" }}
              mx={{ base: "5%", sm: "4%", md: "1.666%", lg: "2%" }}
              h={{ base: "18rem", sm: "20rem", md: "22rem" }}
              mb={{ base: "1rem", md: "2rem" }}
              _hover={{
                boxShadow: BOXSHADOW,
              }}
            >
              <Link to={product?.route}>
              <Flex  
                flexDir="column"
                borderRadius="5px"
                backgroundImage={`url(${product?.image})`}
                backgroundSize="cover"
                backgroundPosition="center"
                backgroundRepeat="no-repeat" 
                h="50%" 
                w="100%">
                  {/* <Flex 
                    flexDir="column"
                    h="1.5rem"
                    px="0.5rem"
                    minW="4rem"
                    ml="auto"
                    mt="auto"
                    mr="1rem"
                    mb="1rem"
                    bgColor="rgba(255, 255, 255, 0.5)"
                    borderRadius="5px"
                    align="center"
                    justify="center"
                  >
                      <Box 
                        fontSize={{
                          base: "xs",
                          sm: "xs",
                        }}
                        fontWeight="semibold"
                      >
                        {product?.amount}
                      </Box>
                  </Flex> */}
              </Flex>
              </Link>
              <Flex flexDir="column" h="50%" w="100%" p="5%" justify="space-between">
                <Flex flexDir={{base: "row", sm: "column", md: "row"}} w="100%" align={{ base: "end", sm: "start", md: "end"}} justify="space-between">
                  <Link to={product?.route}>
                    <Box  
                      fontSize={{ base: "xs",  md: "xx-small", lg: "xs"}}
                      fontWeight="semibold">
                        {product?.title}
                    </Box>
                  </Link>
                  <Box
                  fontSize="xx-small"
                  color="#828282"
                  >
                    {product?.location}
                  </Box>
                </Flex>
                <Box fontSize={{base: "xx-small", md: "0.7rem", lg: "0.8rem"}}>
                    {product?.description}
                </Box>
                <Flex align="center">
                  {product?.isSoldOut && <Icon as={AiOutlineExclamationCircle} w="0.8rem" color="red" />}
                  <Box mt="2px" ml="2%" color="red" fontSize="xx-small">
                    {product?.isSoldOut ? "Sold Out" : ""}
                  </Box>
                </Flex>
                <Flex align="center" justify="flex-end">
                  <Link to={product?.route}>
                    <Box fontSize="xx-small"
                    fontWeight="semibold">
                      View Details
                    </Box>
                  </Link>
                    <Icon as={MdOutlineDoubleArrow} h="0.6rem" fontWeight="bold" />
                </Flex>
              </Flex>
            </Box>
          );
        })}
      </Flex>
      </Flex>
  );
}

export default React.memo(ProductList);
