import Content from "./content";

function AccessInformation() {
  return (
    <Content title="ACCESSING YOUR INFORMATION">
      You must notify us of any change in your Personal Information by updating
      your Paymyrent account profile through our Services. Any changes will
      affect only future uses of your Personal Information.
      <br />
      <br />
      Subject to applicable law, which might, from time to time, oblige us to
      store your Personal Information for a certain period of time, we will
      respect your wishes to correct inaccurate information. Otherwise, we will
      hold your Personal Information for as long as we believe it will help us
      achieve our objectives as detailed in this Privacy Policy.
      <br />
      <br />
      You can ask us whether we are storing your Personal Information and you
      can ask to receive a copy of that Personal Information. Before sending you
      any Personal Information, we will ask you to provide proof of your
      identity. If you are not able to provide proof of your identity, we
      reserve the right to refuse to send you any Personal Information. We will
      respond as quickly as we can to your requests for details of Personal
      Information we hold about you.
    </Content>
  );
}

export default AccessInformation;
