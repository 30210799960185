import YellowFrame from "../../../assets/yellow-frame.png";
import PinkFrame from "../../../assets/pink-frame.png";

export type RentBenefitModel = {
    key: number;
    title: string;
    delay: string;
    image: string;
    text: string;
  };
  
  export const benefits: RentBenefitModel[] = [
    {
      key: 1,
      title: "Rental Income cover",
      delay: "200",
      image: YellowFrame,
      text: "We specifically designed this product to protect a landlord`s income in the event that their tenant defaults on payment.",
    },
    {
      key: 2,
      title: "Legal Expenses covered",
      delay: "300",
      image: PinkFrame,
      text: "We’re there to handle your legal matters. You do not have to worry about eviction or legal issues. Our team of legal experts will handle all that for you."
    },
  ];