import React, { useState } from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Divider,
  Flex,
  FlexboxProps,
  Icon,
  Image,
  List,
  ListIcon,
  ListItem,
  useDisclosure,
  HStack,
  BoxProps,
  Text,
} from "@chakra-ui/react";

import { headerLinksData, homeHeaderLinksData } from "./header.data";
import { BOXSHADOW, COLORS } from "../../styles/theme";
import NavLinkItem, { NavLinkItemProps } from "../link-item/header-links.component";
import LinkItem from "../link-item/link-item.component";
import Logo from "../../assets/logo.png";
import CustomButton from "../custom-button/custom-button.component";
import MenuDropdown from "../menu-dropdown/menu-dropdown.component";
import { useHistory } from "react-router";

export type MenuItemProps = {
  icon?: any;
  children: string;
  iconColor?: string;
} & NavLinkItemProps & BoxProps;

export const MenuItem = ({ children, iconColor="#C4C4C4", ...otherProps }: MenuItemProps) => (
  <HStack align="center" my="0.3rem" mr={{ base: "2rem", md: "3rem", lg: "4rem"}} ml="0.5rem" {...otherProps}>
    <Text fontSize="xs" fontWeight="medium">{children}</Text>
  </HStack>
);

type HeaderProps = FlexboxProps;

const Header: React.FC<HeaderProps> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const [showList, setShowList] = useState(false);


  const dropdownMenuItems = [
    {
      children: (
        <MenuItem>
          LandLord Loan
        </MenuItem>
      ),
      fontSize: "0.9rem",
      onClick: () => history.push("/landlord"),
    },
    {
      children: (
        <MenuItem>
          Landlord Rent Guaranteed
        </MenuItem>
      ),
      fontSize: "0.9rem",
      onClick: () => history.push("#"),
    },
    {
      children: (
        <MenuItem>
          Property Management
        </MenuItem>
      ),
      fontSize: "0.9rem",
      onClick: () => history.push("/landlord-property-manage"),
    },
    {
      children: (
        <MenuItem>
          Renovate Property
        </MenuItem>
      ),
      fontSize: "0.9rem",
      onClick: () => history.push("/landlord-property-renovate"),
    },
    // {
    //   children: (
    //     <MenuItem>
    //       Tenant Screening
    //     </MenuItem>
    //   ),
    //   fontSize: "0.9rem",
    //   onClick: () => history.push("/"),
    // },
  ]

  return (
    <>
      <Flex
        zIndex={3}
        as="header"
        position="fixed"
        py={{ base: "0.6rem", sm: "0.8rem" }}
        width="100%"
        bgColor="white"
        boxShadow={BOXSHADOW}
      >
        <Flex
          flexDir="row"
          width="100%"
          mx={{ base: "2%", md: "5%" }}
          align="center"
          justify="space-between"
        >
          <Box display={{ base: "flex", md: "none" }}>
            <Icon as={AiOutlineMenuUnfold} w={8} h={8} onClick={onOpen} />
            <Drawer
              isOpen={isOpen}
              placement="left"
              onClose={onClose}
              size="xs"
            >
              <DrawerOverlay>
                <DrawerContent>
                  <DrawerCloseButton
                    _focus={{
                      borderColor: "none",
                      boxShadow: "none",
                    }}
                  />
                  <DrawerBody px="0" pt="3rem">
                    <Image src={Logo} alt="logo" h="2rem" mb="2rem" ml="2rem" />
                    <List>
                      <Divider my="1rem" />
                      {headerLinksData.map((headerLink: any) => {
                        return (
                          <ListItem key={headerLink.key}>
                            <ListIcon
                              as={headerLink.as}
                              h="1.3rem"
                              w="1.3rem"
                              mx="1.5rem"
                              color={COLORS.PRIMARY_COLOR}
                            />
                           {headerLink.key === 1 ? 
                              <>
                                <Box 
                                as="span" 
                                fontSize={{ base: "0.7rem", md: "0.65rem", lg: "0.8rem" }}
                                fontWeight="medium"
                                textDecoration="none"
                                color="blackAlpha.800" onClick={() => setShowList(!showList)}>
                                  {headerLink.title}
                                </Box>
                                {showList &&
                                  <List>
                                  <Divider my="1rem" />
                                    {homeHeaderLinksData.map((headerLink: any, index) => {
                                      return (
                                        <ListItem key={headerLink.key}>
                                          <ListIcon
                                            as={headerLink.as}
                                            h="1.3rem"
                                            w="1.3rem"
                                            ml="3rem"
                                            color={COLORS.PRIMARY_COLOR}
                                          /> 
                                          <NavLinkItem
                                            aria-label={headerLink.title}
                                            url={headerLink.url}
                                            ml="1rem"
                                          >
                                            {headerLink.title}
                                          </NavLinkItem>
                                          {
                                            homeHeaderLinksData.length - 1 !== index && <Divider my="1rem" />
                                          }
                                        </ListItem>
                                      );
                                    })}
                                  </List>
                                }
                              </> 
                              : headerLink.key === 2 ?
                              <Box
                                as="span"
                                fontSize={{ base: "0.7rem", lg: "0.8rem" }}
                                fontWeight="medium"
                                textDecoration="none"
                                _hover={{ textDecoration: "none", color: "inherit" }}
                                color="blackAlpha.800" cursor="pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href =
                                    "https://paymyrenthq.com/";
                                }}
                              >
                                {headerLink.title}
                              </Box> :
                              <NavLinkItem
                                aria-label={headerLink.title}
                                url={headerLink.url}
                              >
                                {headerLink.title}
                              </NavLinkItem>
                          }
                            <Divider my="1rem" />
                          </ListItem>
                        );
                      })}
                    </List>
                  </DrawerBody>
                </DrawerContent>
              </DrawerOverlay>
            </Drawer>
          </Box>
          <NavLinkItem
            display={{ base: "none", md: "flex" }}
            w={{ md: "15%", lg: "18%" }}
            aria-label="homepage"
            url="/"
          >
            <Image src={Logo} alt="logo" w={{ base: "10rem", md: "10rem" }} />
          </NavLinkItem>
          <Flex
            as="nav"
            w={{ md: "50%", lg: "52%" }}
            justify="flex-end"
            display={{ base: "none", md: "flex" }}
            direction="row"
            pl={{ base: "none", sm: "1rem", md: "none" }}
            align="center"
          >
            {headerLinksData.map((headerLink: any) => {
              return (
                <Box key={headerLink.key} mr="10%">
                  {headerLink.key === 1 ? 
                    <MenuDropdown menuItems={dropdownMenuItems}>
                      <NavLinkItem
                        aria-label={headerLink.title}
                        url={headerLink.url}
                      >
                        {headerLink.title}
                      </NavLinkItem>
                    </MenuDropdown> : 
                    headerLink.key === 2 ?
                    <Box 
                      fontSize={{ base: "0.7rem", lg: "0.8rem" }}
                      fontWeight="medium"
                      textDecoration="none"
                      _hover={{ textDecoration: "none", color: "inherit" }}
                      color="blackAlpha.800" cursor="pointer" onClick={(e) => {
                        e.preventDefault();
                        window.location.href =
                          "https://paymyrenthq.com/";
                      }}
                    >
                      {headerLink.title}
                    </Box> :
                    <NavLinkItem
                      aria-label={headerLink.title}
                      url={headerLink.url}
                    >
                      {headerLink.title}
                    </NavLinkItem>
                }
                </Box>
              );
            })}
          </Flex>
          <Flex
            align="center"
            justify="flex-end"
            w={{ base: "70%", sm: "52%", md: "35%", lg: "30%" }}
          >
            <LinkItem
              border={`1px solid ${COLORS.PRIMARY_COLOR}`}
              borderRadius='5px'
              p='5px 12px'
              mr={{ base: "10%",  md: "10%" }}
              isAnchor={false}
              to={{ pathname: "https://dashboard.paymyrent.ng/account/login" }}
              target="_parent"
            >
              Sign In
            </LinkItem>
            <CustomButton>Create Account</CustomButton>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default React.memo(Header);
