import React from "react";
import ContactBanner from "../components/blog/contact-us/contact-banner";
import Download from "../components/blog/download/download";
import FollowUs from "../components/blog/follow-us/follow-us";
import Footer from "../components/footer/footer";
import Header from "../components/header/header.component";

function ContactUs() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <section>
      <Header />
      <ContactBanner />
      <FollowUs />
      <Download />
      <Footer />
    </section>
  );
}

export default React.memo(ContactUs);
