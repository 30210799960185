import React from "react";
import Footer from "../components/footer/footer";
import Header from "../components/header/header.component";
import InvestmentDetailsComponent from "../components/investment-details/investment-details";

function InvestmentDetails() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
  return (
    <section>
      <Header />
      <InvestmentDetailsComponent />
      <Footer />
    </section>
  );
}

export default React.memo(InvestmentDetails);
