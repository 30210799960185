import { Box, BoxProps, Flex, Image } from "@chakra-ui/react";
import React from "react";
import { COLORS } from "../../../styles/theme";

type Props = {
  image: string;
  title: string;
  text: string;
  benefitId: number;
} & BoxProps;

const Benefit: React.FC<Props> = ({ image, title, text, benefitId, ...props }) => {
  return (
    <Flex
      flexDir={{ base: "column", sm: benefitId === 2 ? "row-reverse" : "row" }}
      bgColor={COLORS.BACKGROUND_COLOR}
      minH={{ base: "13rem", sm: "15rem" }}
      width="100%"
      pt="10%"
      mt="1rem"
      justify="space-between"
    >
      <Image
        alt=""
        // data-aos="fade-right"
        // data-aos-delay="500"
        src={image}
        h={{ base: "15rem", sm: "100%", xl: "100%" }}
        w={{ base: "100%", sm: "45%", lg: "40%" }}
        mb={{ base: "0.5rem", sm: "0"}}
      />
      <Flex
        flexDir="column"
        justify="center"
        align="start"
        w={{ base: "100%", sm: "50%", lg: "50%" }}
      >
        <Box
          fontSize={{ base: "md", md: "xl", lg: "2xl" }}
          textAlign="start"
          fontWeight="semibold"
          mb={{base: "0.4rem", md: "0.6rem"}}
        >
          {benefitId === 1 ? "01." : benefitId === 2 ? "02." : "03."}
        </Box>
        <Box
          fontSize={{ base: "md", md: "xl", lg: "2xl" }}
          textAlign="start"
          fontWeight="semibold"
          color={COLORS.PRIMARY_COLOR}
          mb={{base: "0.7rem", md: "1rem"}}
        >
          {title}
        </Box>
        <Box
          fontSize={{ base: "xs", md: "sm", lg: "md" }}
          textAlign="start"
        >
          {text}
        </Box>
      </Flex>
    </Flex>
  );
};

export default React.memo(Benefit);
