import { Box, BoxProps, Flex, Image } from "@chakra-ui/react";
import React from "react";

type Props = {
  title: string;
  titleColor: string;
  image: string;
  description: string;
} & BoxProps;

const ManageBenefit: React.FC<Props> = ({ title, titleColor, image, description, ...props }) => {
  return (
    <Flex
      flexDir="column"
      w={{ base: "42%", md: "42%" }}
      mx={{ base: "4%", md: "4%" }}
      borderRadius="5px"
      px={{ base: "5%", sm: "5%" }}
      align="center"
      justify="center"
      textAlign="center"
      {...props}
    >
      <Image src={image} w="1.5rem" h="auto" />
      <Box
        fontSize={{ base: "xs", sm: "sm", md: "md" }}
        fontWeight="semibold"
        color={titleColor}
        mt={{ base: "0.5rem", md: "0.8rem"}}
      >
        {title}
      </Box>
      <Box
        fontSize={{ base: "xx-small", sm: "xs", md: "sm" }}
        mt={{ base: "1rem", md: "1.5rem"}}
      >
        {description}
      </Box>
    </Flex>
  );
};

export default React.memo(ManageBenefit);
