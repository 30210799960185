import {
	Button,
	Menu,
	MenuButton,
	MenuButtonProps,
	MenuItem,
	MenuItemProps,
	MenuList,
	Portal,
	useDisclosure,
} from "@chakra-ui/react";
import React from "react";

type Props = {
	menuItems: MenuItemProps[];
	firstText?:  string;
} & MenuButtonProps;

const MenuDropdown: React.FC<Props> = ({ menuItems, ...otherProps }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<Menu isOpen={isOpen} placement="auto-start" isLazy>
			<MenuButton
				as={Button}
				background="none"
				px={0}
				_hover={{ background: "none" }}
				_active={{ background: "none" }}
				_focus={{ boxShadow: "none" }}
				outline="none"
				border="none"
				onMouseEnter={onOpen}
				{...otherProps}
			/>
			<Portal>
				<MenuList onMouseLeave={onClose} minW="11rem" zIndex={6}>
					{menuItems.map((item, idx) => (
						<MenuItem {...item} key={idx} />
					))}
				</MenuList>
			</Portal>
		</Menu>
	);
};

export default React.memo(MenuDropdown);