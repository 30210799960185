import { Box, Flex, Icon } from "@chakra-ui/react";
import Aos from "aos";
import React from "react";
import {GrLocation} from "react-icons/gr";
import InvestmentProduct from "../../assets/investment-product.png";
import CustomButton from "../custom-button/custom-button.component";
import DetailsColumn from "./details-column";

function InvestmentDetails() {
  React.useEffect(() => {
    Aos.init({ duration: 2000, once: true });
  }, [])

  return (
    <Flex
        flexDir="column"
        py={{ base: "10%", sm: "8%", md: "6%"}}
        mt="3rem"
        bgColor="#FFFAFA"
        minH={{ base: "20rem", sm: "25rem", md: "30rem", xl: "35rem" }}
        width="100%"
        px={{ base: "5%", sm: "10%" }}
    >
        <Flex  
            flexDir="column"
            borderRadius="5px"
            backgroundImage={`url(${InvestmentProduct})`}
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundRepeat="no-repeat" 
            h={{ base: "22rem", sm: "25rem", md: "30rem", lg: "35rem" }}
            w="100%">
            {/* <Flex 
                flexDir="column"
                h={{ base: "2rem", sm: "2.5rem"}}
                w={{ base: "7rem", sm: "10rem"}}
                ml="auto"
                mt="auto"
                mr="1rem"
                mb="1rem"
                bgColor="rgba(255, 255, 255, 0.3)"
                borderRadius="5px"
                align="center"
                justify="center"
                >
                    <Box 
                        color="white"
                        fontSize={{
                            base: "0.7rem",
                            sm: "xs",
                            lg: "sm"
                        }}
                        fontWeight="semibold"
                    >
                        1 year: 20% ROI
                    </Box>
            </Flex> */}
        </Flex>
        <Box 
            mt={{base: "1rem", sm: "1.5rem", lg: "2rem" }}
            fontSize={{ base: "md", md: "lg"}}
            fontWeight="semibold">
                Paymyrent savers Resort
        </Box>
        <Flex mt="0.5rem" align="center">
            <Icon as={GrLocation} w="1rem"  />
            <Box
                fontSize={{ base: "0.6rem", sm: "0.7rem", md: "0.8rem"}}
                fontWeight="semibold"
                color="#828282"
                ml="2%"
                >
                Abeokuta, Ogun state
            </Box>
        </Flex>
        <Flex mt="1rem" align="center" fontSize={{ base: "0.7rem",  sm: "0.8rem", md: "0.9rem"}}>
            <Box
                fontWeight="medium"
                color="red"
                >
                Minimum Investment:
            </Box>
            <Box
                fontWeight="semibold"
                ml="2%"
                >
                #10,000 per month for 10 months 
            </Box>
        </Flex>
        <DetailsColumn title="Location of the resort" text="The Paymyrent savers resort is located at Abeokuta. The proposed location is beside the GOLF resort at Abeokuta which is 1 minute drive to both Obasanjo presidential library & resort and Otunba Gbenga Daniel’s Conference Hotel." />
        <DetailsColumn title="How much am I to invest?" text="Investment amount is minimum of N10,000 and Maximum of N100,000 per month for a duration of 10 months. This means that the amount you select to invest monthly must be consistent for the next 10 months. E.g, N10,000 monthly for 10 months will bring your investment to a total of N100,000." />
        <DetailsColumn title="How much will I earn?" text="We have signed up a management deal with a Top hotel management company and a guaranteed minimum of 15% interest per annum has been signed. This means that if you invest a total of N100,000, the minimum you will earn in a year will be N15,000. Note that this is a minimum, you can earn as high as N40,000. This is dependent on the sales generated from the resort monthly. You earn every month from the resort and you earn for life." />
        <DetailsColumn title="Who owns the estate?" text="Every Paymyrent investor in the project owns the resort. You own it for life until there is a decision to sell." />
        <DetailsColumn title="When do I start earning?" text="Earning starts after the project completion and the resort is opened. Proposed date of opening is October 1, 2023. You start earning from November 1, 2023." />
        <DetailsColumn title="Can I lodge at the Resort?" text="As a saver, you get a membership card that gives you access to lodge at the resort at a member’s rate." />
        <DetailsColumn title="Can I Invest with my Family and Friends?" text="You are allowed to ONLY invite a maximum of 3 family and friends to invest in the Project. You must have included their Name, email and Phone number as at the time of your registration." />
        <DetailsColumn title="When do I start to Pay?" text="Payment starts in the Month of October." />
        <DetailsColumn title="Can I invest more than N10,000 per Month?" text="Yes, you can invest between N10,000 and N100,000 monthly." />
        {/* <DetailsColumn title="Inspection" text="You want to inspect the facility? Book for an inspection by sending a mail to support@paymyrent.ng" /> */}
        <CustomButton 
            onClick={(e) => {
                e.preventDefault();
                window.location.href =
                "https://docs.google.com/forms/d/e/1FAIpQLSdjYYK7PaiJdaKmFNQjocS7RG5Ig9qsEhjZC3pZ_djQaCBZ1g/viewform";
            }} 
            w="10rem" 
            h="2.5rem" 
            mb="1rem" 
            mt="3rem" 
            alignSelf="center"
        >
            Apply Now
        </CustomButton>
    </Flex>
  );
}

export default React.memo(InvestmentDetails);
