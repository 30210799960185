import React from "react";
import Footer from "../components/footer/footer";
import Header from "../components/header/header.component";
import Overview from "../components/investment/overview/overview";
import ProductList from "../components/investment/product-list/product-list";

function Investment() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <section>
      <Header />
      <Overview />
      <ProductList />
      <Footer />
    </section>
  );
}

export default React.memo(Investment);
