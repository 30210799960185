import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import Aos from "aos";
import { COLORS } from "../../../styles/theme";
import PropertyBenefit from "./property-benefit";
import { PropertyBenefitModel, propertyBenefits } from "./property-benefits-data";

function Benefits() {
  React.useEffect(() => {
    Aos.init({ duration: 500, once: true });
  }, []);

  return (
    <Flex
      flexDir="column"
      align="center"
      bgColor={COLORS.BACKGROUND_COLOR}
      minH={{ base: "18rem", sm: "22rem" }}
      width="100%"
      py={{ base: "2rem", md: "8rem" }}
      px={{ base: "5%", sm: "10%" }}
    >
      <Flex flexDir="column" align="center">
        <Box 
          fontSize={{
            base: "xs",
            sm: "sm",
            lg: "md",
          }}
          color={COLORS.PRIMARY_COLOR}
        >
          What’s the step?
        </Box>
        <Box 
          fontWeight="medium"
          fontSize={{
            base: "md",
            sm: "lg",
            md: "xl",
            lg: "2xl",
            xl: "3xl",
          }}
        >
          Here`s what you need us for:
        </Box>
      </Flex>
      <Flex flexDir="column">
        {propertyBenefits.map((benefit: PropertyBenefitModel) => {
          return (
            <PropertyBenefit
              data-aos="flip-right"
              data-aos-delay={benefit.delay}
              benefitId={benefit.key}
              image={benefit.image}
              title={benefit.title}
              text={benefit.text}
            />
          );
        })}
      </Flex>
    </Flex>
  );
}

export default React.memo(Benefits);
