import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import { COLORS } from "../../../styles/theme";
import TechnologyImage from "../../../assets/technology.png";
import Aos from "aos";

function Technology() {
  React.useEffect(() => {
    Aos.init({ duration: 2000, once: true });
  }, []);

  return (
      
      <Flex
        bgColor={COLORS.BACKGROUND_COLOR}
        minH={{ base: "13rem", sm: "15rem" }}
        width="100%"
        py="10%"
        px={{ base: "5%", sm: "10%" }}
        justify="space-between"
        flexDir={{ base: "column", sm: "row" }}
        w="100%"
      >
        <Flex display={{ base: "none", sm: "flex" }} justify="center" w={{ base: "100%", sm: "45%" }}>
          <Image
            alt=""
            data-aos="fade-right"
            data-aos-delay="200"
            src={TechnologyImage}
            h={{ base: "20rem", sm: "100%" }}
            w={{ base: "90%", sm: "90%", md: "80%" }}
          />
        </Flex>
        <Flex px={{ base: "5%", sm: "0" }} flexDir="column" my="auto" w={{ base: "100%", sm: "50%" }}>
            <Box
            w="100%"
            fontSize={{ base: "lg", md: "xl" }}
            fontWeight="semibold"
            textAlign={{ base: "center", sm: "start" }}
            mb="1rem"
            >
                Technology built for Transparency
            </Box>
            <Box
            data-aos="zoom-in"
            data-aos-delay="500"
            textAlign={{ base: "center", sm: "start"}}
            mt={{ base: "5%", sm: "0" }}
            fontSize={{ base: "xs", md: "sm" }}
            >
                Our proprietary technology gives you an unprecedented look at what’s going on with your properties, accessible 24/7 on your phone, computer, or tablet. From real-time maintenance request from your tenant and real time job completion signoff. 
            </Box>
        </Flex>
      </Flex>
  );
}

export default React.memo(Technology);