import { Flex } from "@chakra-ui/react";
import { COLORS } from "../../styles/theme";
import Background from "./components/background";
import Application from "./components/application";
import Information from "./components/information";
import UseInformation from "./components/use-information";
import ShareInformation from "./components/share-information";
import OutRight from "./components/out-right";
import AccessInformation from "./components/access-information";
import InformationShare from "./components/information-share";
import Security from "./components/security";
import ChildrenPrivacy from "./components/children-privacy";
import ThirdParty from "./components/third-party";
import PrivacyPolicy from "./components/privacy-policy";
import Contact from "./components/contact";
import React from "react";

const PrivacyContent = () => {
  return (
    <Flex
      flexDir="column"
      w="100%"
      bgColor={COLORS.BACKGROUND_COLOR}
      minH="90vh"
      pt="8%"
      pb="7%"
      px={{ base: "5%", sm: "10%" }}
    >
      <Flex
        w="100%"
        justify="center"
        fontSize={{ base: "lg", md: "xl", lg: "2xl" }}
        fontWeight="bold"
      >
        Data Privacy, Protection & Cookie Policy
      </Flex>
      <Background />
      <Application />
      <Information />
      <UseInformation />
      <ShareInformation />
      <OutRight />
      <AccessInformation />
      <InformationShare />
      <Security />
      <ChildrenPrivacy />
      <ThirdParty />
      <PrivacyPolicy />
      <Contact />
    </Flex>
  );
};

export default React.memo(PrivacyContent);
