import InvestmentProduct from "../../../assets/investment-product.png";
// import Maize from "../../../assets/maize.jpg";
// import RealEstate from "../../../assets/real_estate.jpg";
// import Tomato from "../../../assets/tomato.jpg";

export type Product = {
  key: number;
  image: string;
  delay: string;
  title: string;
  location: string;
  description: string;
  amount: string;
  route: string;
  isSoldOut: boolean;
};

export const products: Product[] = [
  {
    key: 1,
    image: InvestmentProduct,
    delay: "0",
    title: "Paymyrent savers Resort",
    location: "Abeokuta, Ogun State",
    description: "Invest in commercial real estate for as little as #10,000 per month for 10 months. Co-Own the property, earn monthly income and watch your money grow.",
    amount: "#10,000 per month",
    route: '/investment-details',
    isSoldOut: false,
  },
  // {
  //   key: 2,
  //   image: Tomato,
  //   delay: "20",
  //   title: "Tomato Farm",
  //   location: "Ibadan, Oyo",
  //   description: "Tomato is the most consumed fruit in the world.",
  //   amount: "N30,000",
  //   route: '#',
  //   isSoldOut: true
  // },
  // {
  //   key: 3,
  //   image: Maize,
  //   delay: "40",
  //   title: "Maize Farm",
  //   location: "Abeokuta, Ogun",
  //   description: "Maize Farm Investment.",
  //   amount: "N60,000",
  //   route: '#',
  //   isSoldOut: true
  // },
  // {
  //   key: 4,
  //   image: RealEstate,
  //   delay: "60",
  //   title: "Real Estate",
  //   location: "Lekki, Lagos",
  //   description: "Real Estate Investment.",
  //   amount: "N100,000.00",
  //   route: '#',
  //   isSoldOut: true
  // },
  // {
  //   key: 5,
  //   image: ProductImage,
  //   delay: "80",
  //   title: "Tomato Farm",
  //   location: "Ibadan, Oyo",
  //   description: "Tomato is the most consumed fruit in the world.",
  //   amount: "N30,000.00",
  //   route: '#',
  //   isSoldOut: true
  // },
  // {
  //   key: 6,
  //   image: ProductImage,
  //   delay: "100",
  //   title: "Maize Farm",
  //   location: "Abeokuta, Ogun",
  //   description: "Maize Farm Investment.",
  //   amount: "N60,000",
  //   route: '#',
  //   isSoldOut: true
  // },
];
