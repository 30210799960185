import { ChakraProvider, CSSReset, theme } from "@chakra-ui/react";
import { Route, Switch } from "react-router-dom";
import Home from "./pages/home";
import { customTheme } from "./styles/theme";
import "aos/dist/aos.css";
import ContactUs from "./pages/contact-us";
import FAQs from "./pages/faqs";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import Investment from "./pages/investment";
import InvestmentDetails from "./pages/investment-details";
import React from "react";
import LandlordLoan from "./pages/landlord-loan";
import LandlordRent from "./pages/landlord-rent";
import LandlordProperty from "./pages/landlord-property";
import LandlordManage from "./pages/landlord-manage";
import Landing from "./pages/landing";

function App() {
  return (
    <ChakraProvider theme={{ ...theme, ...customTheme }}>
      <CSSReset />
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/tenant" component={Home} />
          <Route exact path="/investment" component={Investment} />
          <Route exact path="/investment-details" component={InvestmentDetails} />
          <Route exact path="/faq" component={FAQs} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/landlord" component={LandlordLoan} />
          <Route exact path="/landlord-rent" component={LandlordRent} />
          <Route exact path="/landlord-property-renovate" component={LandlordProperty} />
          <Route exact path="/landlord-property-manage" component={LandlordManage} />
        </Switch>
    </ChakraProvider>
  );
}

export default (React.memo(App));