import { Box, BoxProps, Flex, Image } from "@chakra-ui/react";
import React from "react";
import { BOXSHADOW, COLORS } from "../../../styles/theme";
import CheckImage from "../../../assets/check.png";

type Props = {
  title: string;
  description: string;
} & BoxProps;

const LoanBenefit: React.FC<Props> = ({ title, description, ...props }) => {
  return (
    <Flex
      flexDir="column"
      w={{ base: "90%", sm: "42%", md: "29.3%" }}
      mx={{ base: "5%", sm: "4%", md: "2%" }}
      h={{ base: "12rem", sm: "14rem", md: "16rem" }}
      mb={{ base: "3rem", md: "5rem" }}
      borderRadius="5px"
      minH="6rem"
      p={{ base: "5%", sm: "2%" }}
      bgColor="#FEE0D7"
      align="center"
      textAlign="center"
      boxShadow={BOXSHADOW}
      _hover={{
        transform: "scale(1.1)"
      }}
      {...props}
    >
      <Image 
        alt="" 
        src={CheckImage}
        w="1.8rem"
      />
      <Box
        fontSize={{ base: "sm", md: "md" }}
        fontWeight="semibold"
        color={COLORS.PRIMARY_COLOR}
        mt="0.5rem"
      >
        {title}
      </Box>
      <Box
        fontSize={{ base: "xs", md: "sm" }}
        mt="1rem"
      >
        {description}
      </Box>
    </Flex>
  );
};

export default React.memo(LoanBenefit);
