import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import Aos from "aos";
import { COLORS } from "../../../styles/theme";
import { benefits, RentBenefitModel } from "./rent-benefit-data";
import RentBenefit from "./rent-benefit";

function LoanBenefits() {
  React.useEffect(() => {
    Aos.init({ duration: 500, once: true });
  }, []);

  return (
    <Flex
      flexDir="column"
      align="center"
      bgColor="rgba(249, 0, 0, 0.05)"
      minH={{ base: "18rem", sm: "22rem" }}
      width="100%"
      pt={{ base: "2rem", md: "5rem" }}
      pb="2rem"
      px={{ base: "5%", sm: "10%" }}
    >
      <Flex flexDir="column" align="center">
        <Box 
          fontSize={{
            base: "xs",
            sm: "sm",
            lg: "md",
          }}
          mb="0.5rem"
          color={COLORS.PRIMARY_COLOR}
        >
          Awoof Flexing
        </Box>
        <Box 
          fontWeight="semibold"
          fontSize={{
            base: "sm",
            sm: "lg",
            md: "xl",
            lg: "2xl",
          }}
          mb={{ base: "2rem", md: "3rem"}}
        >
          Benefit of Rent Guarantee
        </Box>
      </Flex>
      <Flex flexWrap="wrap" mt={{ base: "0.7rem", md: "1rem" }}>
        {benefits.map((benefit: RentBenefitModel) => {
          return (
            <RentBenefit
              data-aos="flip-right"
              image={benefit.image}
              // data-aos-delay={benefit.delay}
              title={benefit.title}
              description={benefit.text}
            />
          );
        })}
      </Flex>
    </Flex>
  );
}

export default React.memo(LoanBenefits);
