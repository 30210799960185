import React from "react";
import Footer from "../components/footer/footer";
import Header from "../components/header/header.component";
import OtherBenefits from "../components/landlord-manage/benefits/other-benefits";
import ManageBanner from "../components/landlord-manage/manage-banner/manage-banner.component";
import ManageBenefits from "../components/landlord-manage/manage-benefits/manage-benefits";
import Technology from "../components/landlord-manage/technology/technology";

function LandLordManage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <section>
      <Header />
      <ManageBanner />
      <ManageBenefits />
      <OtherBenefits />
      <Technology />
      <Footer />
    </section>
  );
}

export default React.memo(LandLordManage);
