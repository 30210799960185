import Content from "./content";

const Application = () => {
  return (
    <Content title="APPLICATION OF THE PRIVACY COMPANY">
      This privacy policy (this "Privacy Policy") explains how personal
      information is collected, used, stored, and disclosed by Paymyrent
      Nigeria, ("Paymyrent," "we," "us," and "our"). This Privacy Policy applies
      to consumer users (individually referred to as "you") of our websites,
      applications, and other online services to which this Privacy Policy is
      posted (collectively, our "Services").
      <br />
      By accessing or using our Services, you agree to this Privacy Policy and
      our Terms of Use. The provisions contained in this Privacy Policy
      supersede all previous notices and statements regarding our privacy
      practices with respect to our Services. If you do not agree to every
      provision of this Privacy Policy and our Terms of Use, you may not access
      or use our Services.
    </Content>
  );
};

export default Application;
