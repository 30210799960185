import React from "react";
import Footer from "../components/footer/footer";
import Header from "../components/header/header.component";
import RentBanner from "../components/landlord-rent/rent-banner/rent-banner.component";
import RentAccess from "../components/landlord-rent/access/access.component";
import WhatToDo from "../components/landlord-rent/what-to-do/what-to-do.component";
import RentBenefits from "../components/landlord-rent/rent-benefits/rent-benefits";

function LandLordRent() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <section>
      <Header />
      <RentBanner />
      <RentAccess />
      <WhatToDo />
      <RentBenefits />
      <Footer />
    </section>
  );
}

export default React.memo(LandLordRent);
