import React from "react";
import Footer from "../components/footer/footer";
import Header from "../components/header/header.component";
import LoanBanner from "../components/landlord-loan/loan-banner/loan-banner";
import LoanBenefits from "../components/landlord-loan/loan-benefits/loan-benefits";

function LandLordLoan() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <section>
      <Header />
      <LoanBanner />
      <LoanBenefits />
      <Footer />
    </section>
  );
}

export default React.memo(LandLordLoan);
