import { Box, Flex, Image } from "@chakra-ui/react";
import Aos from "aos";
import { COLORS } from "../../../styles/theme";
import React from "react";

import Investment from "../../../assets/investment.png";
import MoneyBag from "../../../assets/money-bag.png";

function Overview() {
  React.useEffect(() => {
    Aos.init({ duration: 2000, once: true });
  }, [])

  return (
      <Flex
        flexDir="row"
        justify="space-between"
        bgColor={COLORS.BACKGROUND_COLOR}
        h={{ base: "20rem", sm: "25rem", md: "30rem", xl: "35rem" }}
        width="100%"
        mt="3rem"
        pt="7%"
        pl={{ base: "5%", sm: "10%" }}
      >
        <Box
          display={{ base: "none", sm: "block" }}
          data-aos="slide-right"
          w="50%"
        >
          <Image alt="" src={Investment} w="100%" h="100%" />
        </Box>
        <Flex
          justify="center"
          w={{ base: "100%", sm:"45%" }}
          align={{base: "center",  sm: "flex-start"}}
          flexDir="column"
        >
            <Flex mb="8%" justify="center" w={{ base: "100%", sm: "45%" }}>
                <Image src={MoneyBag} w="2rem" />
            </Flex>
          <Box
            textAlign={{base: "center",  sm: "start"}}
            fontSize={{
              base: "sm",
              sm: "md",
              md: "lg",
              lg: "xl",
              xl: "2xl",
            }}
            fontWeight="bold"
          >
            Investment Overview
          </Box>
          <Box mt="3%" w="70%" fontSize={{ base: "xs", md: "sm", lg: "md" }} textAlign={{base: "center",  sm: "start"}}>
            Build wealth with secure investment plans. Start a low risk investment to earn interest and grow your savings.
          </Box>
          <Flex justify="right" mt="8%" w={{ base: "70%", sm: "60%" }}>
                <Image src={MoneyBag} w="2rem" />
            </Flex>
        </Flex>
      </Flex>
  );
}

export default React.memo(Overview);
