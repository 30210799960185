import House1 from "../../../assets/house1.png";
import House2 from "../../../assets/house2.png";
import House3 from "../../../assets/house3.png";

export type PropertyBenefitModel = {
    key: number;
    image: string;
    title: string;
    delay: string;
    text: string;
  };
  
  export const propertyBenefits: PropertyBenefitModel[] = [
    {
      key: 1,
      image: House1,
      title: "Complete your Project",
      delay: "200",
      text: "We understand that price of materials can go up and in turn, affect your building project. If you need funds to complete your residential property, talk to us today!",
    },
    {
      key: 2,
      image: House2,
      title: "Renovate your existing property",
      delay: "300",
      text: "We’re there to handle renovations that will increase the value of your property. Don’t let your property depreciate, renovate it to standard today."
    },
    {
      key: 3,
      image: House3,
      title: "Renovate to get Tenants",
      delay: "400",
      text: "Do you have a property and you are not able to get tenants or tenants are underpaying you due to the state of the property? Get it renovated today!",
    },
  ];