import { Box, Flex } from "@chakra-ui/react";
import Benefit from "./loan-benefit";
import React from "react";
import Aos from "aos";
import { COLORS } from "../../../styles/theme";
import { LoanBenefitModel, benefits } from "./loan-benefit-data";

function LoanBenefits() {
  React.useEffect(() => {
    Aos.init({ duration: 500, once: true });
  }, []);

  return (
    <Flex
      flexDir="column"
      align="center"
      bgColor="rgba(249, 0, 0, 0.05)"
      minH={{ base: "18rem", sm: "22rem" }}
      width="100%"
      pt={{ base: "2rem", md: "4rem" }}
      px={{ base: "5%", sm: "10%" }}
    >
      <Flex flexDir="column" align="center">
        <Box 
          fontSize={{
            base: "xs",
            sm: "sm",
            lg: "md",
          }}
          color={COLORS.PRIMARY_COLOR}
        >
          Here`s how it works:
        </Box>
        <Box 
          fontWeight="semibold"
          fontSize={{
            base: "md",
            sm: "lg",
            md: "xl",
            lg: "2xl",
            xl: "3xl",
          }}
          mb="2rem"
        >
          Landlord Loan
        </Box>
      </Flex>
      <Flex flexWrap="wrap" mt={{ base: "0.7rem", md: "1rem" }}>
        {benefits.map((benefit: LoanBenefitModel) => {
          return (
            <Benefit
              data-aos="flip-right"
              // data-aos-delay={benefit.delay}
              title={benefit.title}
              description={benefit.text}
            />
          );
        })}
      </Flex>
    </Flex>
  );
}

export default React.memo(LoanBenefits);
