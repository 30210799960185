import { Flex } from "@chakra-ui/react";
import React from "react";
import Aos from "aos";
import { benefits, ManageBenefitModel } from "./manage-benefit-data";
import ManageBenefit from "./manage-benefit";

function ManageBenefits() {
  React.useEffect(() => {
    Aos.init({ duration: 500, once: true });
  }, []);

  return (
    <Flex
      flexDir="column"
      align="center"
      bgColor="rgba(249, 0, 0, 0.05)"
      width="100%"
      py={{ base: "3rem", md: "5rem" }}
      px={{ base: "5%", sm: "10%" }}
    >
      <Flex flexWrap="wrap">
        {benefits.map((benefit: ManageBenefitModel) => {
          return (
            <ManageBenefit
              data-aos="flip-right"
              image={benefit.image}
              // data-aos-delay={benefit.delay}
              title={benefit.title}
              titleColor={benefit.titleColor}
              description={benefit.text}
            />
          );
        })}
      </Flex>
    </Flex>
  );
}

export default React.memo(ManageBenefits);
