import Compliance from "../../../assets/compliance.png";
import Maintenance from "../../../assets/maintenance.png";
import Screening from "../../../assets/screening.png";
import Rent from "../../../assets/rent.png";

export type ManageBenefitModel = {
    key: number;
    title: string;
    delay: string;
    image: string;
    text: string;
  };
  
  export const benefits: ManageBenefitModel[] = [
    {
      key: 1,
      title: "Tenant Screening and Placement",
      delay: "100",
      image: Screening,
      text: "We help you set a rental rate for your properties, market them with beautiful professional photographs, and screen tenants to ensure only the best make it through.",
    },
    {
      key: 2,
      title: "Maintenance",
      delay: "200",
      image: Maintenance,
      text: "We're there to handle maintenance issues large and small. Enjoy the best prices when it comes to maintenance. We have the best professionals offering great services and affordable cost."
    },
    {
      key: 3,
      title: "Rent & Finances",
      delay: "300",
      image: Rent,
      text: "We collect rent and enforce that rent is paid on the due dates. Our owner dashboard gives you a detailed understanding of your portfolio’s performance. What’s more interesting is that we give you rent guarantee. "
    },
    {
      key: 4,
      title: "Compliance & Legal",
      delay: "400",
      image: Compliance,
      text: "We make sure your property is registered and in compliance with local regulations. When legal issues arise, our legal partners are there for you. Eviction is not even your worry. It’s ours."
    },
    {
      key: 5,
      title: "Emergencies and Support",
      delay: "500",
      image: Compliance,
      text: "We're here to handle emergencies with 24/7 phone support. We have created a 24 hours functional customer service unit to attend to you and your tenants. We are constantly on standby."
    },
  ];