import { Box, Flex, Image } from "@chakra-ui/react";
import Aos from "aos";
import React from "react";
import AccessImage from "../../../assets/access.png";
import { COLORS } from "../../../styles/theme";
import { accessList } from "./access-data";

function RentAccess() {
  React.useEffect(() => {
    Aos.init({ duration: 2000, once: true });
  }, []);
  return (
    <Flex
      flexDir={{ base: "column", sm: "row" }}
      bgColor="rgba(249, 0, 0, 0.05)"
      minH={{ base: "13rem", sm: "15rem" }}
      width="100%"
      pt="5%"
      pb="10%"
      px={{ base: "5%", sm: "10%" }}
      justify="space-between"
    >
      <Image
        display={{ base: "none", md: "flex" }}
        alt=""
        data-aos="fade-right"
        data-aos-delay="500"
        src={AccessImage}
        h={{ base: "25rem", sm: "100%", xl: "100%" }}
        w={{ base: "100%", sm: "45%", lg: "40%" }}
      />
      <Flex
        flexDir="column"
        justify="start"
        align="start"
        mt={{ base: "2%", sm: "7%" }}
        w={{ base: "100%", md: "50%", lg: "55%" }}
      >
        <Box
          fontSize={{ base: "xs", md: "sm" }}
          textAlign={{ base: "center", sm: "start" }}
          color={COLORS.PRIMARY_COLOR}
          mb="1rem"
        >
          Who has access
        </Box>
        <Box
          fontSize={{ base: "sm", md: "lg", lg: "xl" }}
          fontWeight="semibold"
          textAlign={{ base: "center", sm: "start" }}
        >
          Who is rent guaranteed for?
        </Box>
        <Flex flexDir="column">
          {accessList.map((access) => 
            <Flex mt="2rem" align="center" key={access.key}>
                <Box h="6px" w={access.key === 3 ? { base: "6px", md: "15px", lg: "10px"} : { base: "6px", md: "8px", lg: "6px"}} borderRadius="50%" bgColor="black" />
                <Box fontSize={{ base: "xs", md: "sm", lg: "md" }} ml="2rem">{access.text}</Box>
            </Flex>
            )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default React.memo(RentAccess);
