import Content from "./content";

function ShareInformation() {
  return (
    <Content title="HOW WE SHARE INFORMATION">
      We do not share your Personal Information with: (1) other financial
      companies for joint marketing purposes; (2) affiliated companies for their
      everyday business purposes; or (3) any third parties so they can market to
      you.
      <br />
      <br />
      We may share your Personal Information with unaffiliated third parties:
      (1) if you request or authorize it; (2) if the information is provided to
      help complete a transaction for you; (3) if the information is provided
      to: (a) comply with applicable laws, rules, regulations, governmental and
      quasi-governmental requests, court orders, or subpoenas; (b) enforce our
      Terms of Use or other agreements; or (c) protect our rights, property, or
      safety or the rights, property, or safety of our users or others (e.g., to
      a consumer reporting agency for fraud protection, etc.); (4) if the
      disclosure is done as part of a purchase, transfer, or sale of services or
      assets (e.g., in the event that substantially all of our assets are
      acquired by another party, your Personal Information may be one of the
      transferred assets); (5) if the information is provided to our third-party
      service providers to perform functions on our behalf (e.g., analyzing
      data, providing marketing assistance, providing customer service,
      processing orders, etc.); (6) for our everyday business purposes; or (7)
      as permitted by applicable law or otherwise described in this Privacy
      Policy. When you are no longer our customer, we continue to share your
      information as described in this Privacy Policy.
      <br />
      <br />
      We may disclose Analytics with third parties as described elsewhere in
      this Privacy Policy and for our commercial purposes.
    </Content>
  );
}

export default ShareInformation;
