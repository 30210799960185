import Content from "./content";

function ThirdParty() {
  return (
    <Content title="LINKS TO THIRD- PARTY WEBSITES">
      When you use our Services, you may be directed to other websites that are
      beyond our control. We may also allow third-party websites or applications
      to link to our Services. We are not responsible for the privacy practices
      of any third parties or the content of linked websites, but we do
      encourage you to read the applicable privacy policies and terms and
      conditions of such parties and websites. This Privacy Policy only applies
      to our Services.
    </Content>
  );
}

export default ThirdParty;
