import Content from "./content";

function InformationShare() {
  return (
    <Content title="INFORMATION YOU SHARE SOCIALLY">
      Our Services may allow you to connect and share your actions, comments,
      content, and information publicly or with friends. We are not responsible
      for maintaining the confidentiality of any information you share publicly
      or with friends.
      <br />
      <br />
      Our Services may also allow you to connect with us on, share on, and use
      third-party websites, applications, and services. Please be mindful of
      your personal privacy needs and the privacy needs of others, as you choose
      whom to connect with and what to share and make public. We cannot control
      the privacy or security of information you choose to make public or share
      with others. We also do not control the privacy practices of third
      parties. Please contact those sites and services directly if you want to
      learn about their privacy practices.
    </Content>
  );
}

export default InformationShare;
