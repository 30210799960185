import Content from "./content";

function OutRight() {
  return (
    <Content title="OPT-OUT RIGHTS">
      If you do not wish to receive offers or other notices from us in the
      future, you can "opt out" by contacting us as indicated at the end of this
      Privacy Policy or by following the "unsubscribe" instructions in any
      communication you receive from us. Please be aware that you are not able
      to opt out of receiving communications about your Paymyrent account or
      related transactions with us.
    </Content>
  );
}

export default OutRight;
