import { Box, Flex, Image } from "@chakra-ui/react";
import Aos from "aos";
import React from "react";
import WhatTodoImage from "../../../assets/what-to-do.png";
import { COLORS } from "../../../styles/theme";

function WhatTodo() {
  React.useEffect(() => {
    Aos.init({ duration: 2000, once: true });
  }, []);
  return (
    <Flex
      flexDir={{ base: "column", sm: "row" }}
      bgColor={COLORS.BACKGROUND_COLOR}
      minH={{ base: "13rem", sm: "15rem" }}
      width="100%"
      py="8%"
      px={{ base: "5%", sm: "10%" }}
      justify="space-between"
    >
      <Flex
        flexDir="column"
        justify={{ base: "center", md: "start"}}
        align={{ base: "center", md: "start"}}
        mt={{ base: "2%", sm: "3%" }}
        w={{ base: "100%", md: "50%", lg: "55%" }}
        textAlign={{ base: "center", md: "start" }}
      >
        <Box
          fontSize={{ base: "xs", md: "sm" }}
          color={COLORS.PRIMARY_COLOR}
          mb="1rem"
        >
          Who do I do?
        </Box>
        <Box
          fontSize={{ base: "md", md: "lg", lg: "xl" }}
          fontWeight="semibold"
          mb={{ base: "1rem", md: "2rem"}}
        >
          Who is rent guaranteed for?
        </Box>
        <Box
          fontSize={{ base: "xs", md: "xs", lg: "md" }}
        >
          If your tenant stops paying their rent, we will pay you <br />the rent and start the eviction process at no cost to <br />you.
        </Box>
      </Flex>
      <Image
        display={{ base: "none", md: "flex" }}
        alt=""
        data-aos="fade-right"
        data-aos-delay="500"
        src={WhatTodoImage}
        h={{ base: "25rem", sm: "100%", xl: "100%" }}
        w={{ base: "100%", sm: "45%", lg: "40%" }}
      />
    </Flex>
  );
}

export default React.memo(WhatTodo);
