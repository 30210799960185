import React from "react";
import Footer from "../components/footer/footer";
import Header from "../components/header/header.component";
import TermsContent from "../components/terms/terms";

const Terms = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <section>
      <Header />
      <TermsContent />
      <Footer />
    </section>
  );
};

export default React.memo(Terms);
