import Content from "./content";

function ChildrenPrivacy() {
  return (
    <Content title="PROTECTING CHILDREN’S PRIVACY">
      Our Services are not directed, or intended to be attractive, to children
      under the age of 18. We do not knowingly collect Personal Information from
      children under the age of 18. If you are under the age of 18, do not use
      our Services or submit any information to us.
    </Content>
  );
}

export default ChildrenPrivacy;
