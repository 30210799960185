import Footer from "../components/footer/footer";
import Header from "../components/header/header.component";
import React, { Suspense } from "react";
import { SplashComponent } from "../components/splash-screen/splash-screen.component";

const Banner = React.lazy(() => import("../components/home/banner/banner"));
const Benefits = React.lazy(() => import("../components/home/benefits/benefits"));
const CustomerService = React.lazy(() => import("../components/home/customer-service/customer-service"));
const AsFeaturedOn = React.lazy(() => import("../components/home/featured-on/featured-on"));
const Gains = React.lazy(() => import("../components/home/gains/gains"));
const Regulators = React.lazy(() => import("../components/home/regulators/regulators"));
const Goodies = React.lazy(() => import("../components/home/goodies/goodies"));
const DownloadLinks = React.lazy(() => import("../components/home/download-links/download-links"));
const Landlords = React.lazy(() => import("../components/home/landlords/landlords"));

function Home() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <section>
      <Header />
      <Suspense fallback={<SplashComponent />}>
        <Banner />
        <Benefits />
        <AsFeaturedOn />
        <Gains />
        <Landlords />
        <Regulators />
        <Goodies />
        <CustomerService />
        <DownloadLinks />
      </Suspense>
      <Footer />
    </section>
  );
}

export default React.memo(Home);
