import { Box, Flex } from "@chakra-ui/react";
import React from "react";

export type DetailsColumnProps = {
  title: string;
  text: string;
}

const DetailsColumn: React.FC<DetailsColumnProps> = ({
  title,
  text
}) => {
  return (
    <Flex flexDir="column" mt="1.5rem">
    <Box
        fontSize={{ base: "0.7rem", sm: "0.8rem", md: "0.9rem"}}
        fontWeight="semibold"
        >
        {title}
    </Box>
    <Box
        mt="0.5rem"
        fontSize={{base: "0.6rem", sm: "0.7rem", md: "0.8rem", lg: "0.9rem"}}
        >
        {text}
    </Box>
</Flex>
  );
};

export default DetailsColumn;