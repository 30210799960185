import Content from "./content";

function Security() {
  return (
    <Content title="SECURITY">
      We have, and require our third-party service providers that receive
      Personal Information from us to have, a comprehensive written information
      security program that contains administrative, technical, and physical
      safeguards for our respective physical facilities and in our respective
      computer systems, databases, and communications networks that are
      reasonably designed to protect information contained within such systems
      from loss, misuse, or alteration. When your bank account information is
      transmitted via our Services, it will be protected by encryption
      technology, such as Secure Sockets Layer (SSL).
      <br />
      <br />
      No method of electronic transmission or storage is 100% secure. Therefore,
      we cannot guarantee absolute security of your Personal Information. You
      also play a role in protecting your Personal Information. Please safeguard
      your username and password for your Paymyrent account and do not share
      them with others. If we receive instructions using your Paymyrent account
      login information, we will consider that you have authorized the
      instructions. You agree to notify us immediately of any unauthorized use
      of your Paymyrent account or any other breach of security. We reserve the
      right, in our sole discretion, to refuse to provide our Services,
      terminate Paymyrent accounts, and to remove or edit content.
    </Content>
  );
}

export default Security;
