import React from "react";
import Footer from "../components/footer/footer";
import Header from "../components/header/header.component";
import PrivacyContent from "../components/privacy/privacy";

const Privacy = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <section>
      <Header />
      <PrivacyContent />
      <Footer />
    </section>
  );
};

export default React.memo(Privacy);
