import { Component } from "react";
import './splash.css';

export const SplashComponent = () => {
	return (
		<div id="splash">
			<div className="loader-container">
			<div className="loader">
				<div className="sk-chase">
				<div className="sk-chase-dot"></div>
				<div className="sk-chase-dot"></div>
				<div className="sk-chase-dot"></div>
				<div className="sk-chase-dot"></div>
				<div className="sk-chase-dot"></div>
				<div className="sk-chase-dot"></div>
				</div>
			</div>
			</div>
		</div>
	);
};

const withSplashScreen = (WrappedComponent: any) => {
	return class extends Component {
		state = {
			loading: true,
		};

		componentDidMount() {
			setTimeout(() => {
				this.setState({
					loading: false,
				});
			}, 1500);
		}

		render() {
			// while checking user session, show "loading" message
			if (this.state.loading) return SplashComponent();

			// otherwise, show the desired route
			return <WrappedComponent {...this.props} />;
		}
	};
};

export default withSplashScreen;
