import { Box, Flex, Image } from "@chakra-ui/react";
import Aos from "aos";
import { COLORS } from "../../../styles/theme";
import PropertyImage from "../../../assets/property-hero.png";
import CustomButton from "../../custom-button/custom-button.component";
import React from "react";

function PropertyBanner() {
  React.useEffect(() => {
    Aos.init({ duration: 2000, once: true });
  }, []);
  return (
      <Flex
        flexDir="row"
        bgColor={COLORS.BACKGROUND_COLOR}
        h={{ base: "24rem", sm: "26rem", md: "32rem", xl: "37rem" }}
        width="100%"
        pt={{ base: "0", md: "8%"}}
        pb={{ base: "8%", md: "5%"}}
        px={{ base: "15%", sm: "20%", md: "10%" }}
        justify="space-between"
        align="end"
      >
        <Flex
          justify="space-between"
          w={{ base: "100%", md: "45%"}}
          align={{ base: "center", md: "flex-start"}}
          textAlign={{ base: "center", md: "start"}}
          flexDir="column"
          h={{ base: "70%", sm: "65%", md: "80%"}}
        >
          <Box   
            fontSize={{
              base: "xx-small",
              lg: "xs"
            }}
            fontWeight="medium"
            color={COLORS.PRIMARY_COLOR}
          >
            We upgrade/Renovate your rental properties at no cost to you.
          </Box>
          <Box
            fontSize={{
              base: "md",
              md: "lg",
              lg: "2xl",
            }}
            fontWeight="bold"
          >
            <Box as="span" color={COLORS.PRIMARY_COLOR}>Renovate</Box> your property and  <br />improve its <Box as="span" color={COLORS.PRIMARY_COLOR}>ROI</Box>
          </Box>
          <Box fontSize={{ base: "xs", md: "sm", lg: "md" }}>          
            Get your property renovated and re-modeled to attract better Return on investment at no cost to you. Sometimes, it’s just the little extras that change the game. 
          </Box>
          <CustomButton w="8rem">Apply now</CustomButton>
        </Flex>
        <Flex display={{ base: "none", md: "flex" }} data-aos="slide-left" w="50%" align="center" h="90%">
          <Image alt="" src={PropertyImage} w="auto" h="100%" />
        </Flex>
      </Flex>
  );
}

export default PropertyBanner;
