import FasterSpace from "../../../assets/faster-space.png";
import GoodRecord from "../../../assets/good-record.png";

export type ManageBenefitModel = {
    key: number;
    title: string;
    titleColor: string;
    image: string;
    delay: string;
    text: string;
  };
  
  export const benefits: ManageBenefitModel[] = [
    {
      key: 1,
      title: "Faster Space Filling",
      titleColor: "#076B91",
      delay: "200",
      image: FasterSpace,
      text: "We fill vacant properties faster because we offer tenants what they can’t resist.",
    },
    {
      key: 2,
      title: "Maintain a good Record",
      titleColor: "#F90000",
      delay: "300",
      image: GoodRecord,
      text: "Paymyrent is designed to take the hassle off your plate and keep your portfolio running smoothly."
    },
  ];